import { OnInit } from '@angular/core';
import { Validators } from "@angular/forms";
import { AUTH_CODES } from "../../config/auth-codes.config";
import { CASH_APP_PAYMENT_AMOUNT, PROJECT_URL } from "../../config/project";
import { CHECKOUT_CHARGE_PAYMENT_TYPE } from "../../shared/checkout-charge-popup/checkout-charge-popup.config";
import * as i0 from "@angular/core";
import * as i1 from "../../modules/language/language.service";
import * as i2 from "../../service/phoneMask.service";
import * as i3 from "../../service/loader.service";
import * as i4 from "../../modules/currency/currency.service";
import * as i5 from "../../service/home.service";
import * as i6 from "../../shared/popup/popup.component";
import * as i7 from "@angular/router";
import * as i8 from "@angular/forms";
var LoginComponent = /** @class */ (function () {
    function LoginComponent(languageService, phoneMaskService, loaderService, currencyService, homeService, popupComponent, activatedRoute, formBuilder) {
        this.languageService = languageService;
        this.phoneMaskService = phoneMaskService;
        this.loaderService = loaderService;
        this.currencyService = currencyService;
        this.homeService = homeService;
        this.popupComponent = popupComponent;
        this.activatedRoute = activatedRoute;
        this.formBuilder = formBuilder;
        this.partnerLogo = '../assets/images/logo.png';
        this.step = 1;
        this.isTermsExpandedShown = false;
        this.games = [];
        this.showCashAppPopup = false;
    }
    LoginComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.languageChangeSubscription = this.languageService.languageIsUpdated.subscribe(function (val) {
            if (val) {
                setTimeout(function () {
                    _this.getPromoCodeAndPageInfo();
                });
            }
        });
        this.phoneMask = this.phoneMaskService.setPhoneMask();
        this.loginForm = this.formBuilder.group({
            promo: [null, Validators.required],
            email: [null, Validators.required],
            phone: [null, Validators.required]
        });
        this.keywordsForm = this.formBuilder.group({
            textarea: [null, Validators.required],
            terms: [false, Validators.required],
        });
        this.getPromoCodeAndPageInfo();
    };
    LoginComponent.prototype.getPromoCodeAndPageInfo = function () {
        var _this = this;
        this.activatedRoute.queryParams.subscribe(function (params) {
            var promoCode = params.promo ? params.promo : '';
            if (promoCode) {
                _this.loginForm.controls.promo.setValue(promoCode);
                if (promoCode != '') {
                    _this.loginForm.controls.promo.disable();
                }
            }
            _this.getPageInfo(promoCode);
        });
    };
    LoginComponent.prototype.getPageInfo = function (promoCode) {
        var _this = this;
        this.homeService.getPageInfo(promoCode).subscribe(function (data) {
            try {
                if (data.status) {
                    _this.games = data.games;
                    _this.terms = data.terms;
                }
                else {
                    _this.popupComponent.showPopupError({ text: data.message });
                }
            }
            catch (e) {
                _this.loaderService.hideAndShowTryAgain();
            }
        }, function () {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
        });
    };
    LoginComponent.prototype.showCheckoutTerms = function () {
        document.body.classList.add('hide-menu');
        this.isTermsExpandedShown = true;
    };
    LoginComponent.prototype.closeTermsExpanded = function () {
        document.body.classList.remove('hide-menu');
        this.isTermsExpandedShown = false;
    };
    LoginComponent.prototype.onPhoneBlur = function () {
        if (!this.loginForm.controls.phone.value) {
            this.loginForm.controls.phone.setValue(null);
        }
    };
    LoginComponent.prototype.onContinue = function () {
        var _this = this;
        var email = this.loginForm.controls.email.value;
        var phone = this.loginForm.controls.phone.value;
        var promoCode = this.loginForm.controls.promo.value;
        this.homeService.getUserStatus(email, phone, promoCode).subscribe(function (data) {
            try {
                if (data.status) {
                    if (data.code && (data.code === AUTH_CODES.userNotExists)) {
                        _this.step = 2;
                        _this.cashappInfo = data.cashappInfo;
                        _this.keywordsForm.controls.textarea.setValue("It's a beautiful day in Eden!");
                    }
                    else {
                        _this.loaderService.hideAndShowTryAgain();
                    }
                    _this.loaderService.hide();
                }
                else {
                    _this.checkIfUserExists(data);
                }
            }
            catch (e) {
                _this.loaderService.hideAndShowTryAgain();
            }
        }, function () {
            _this.loaderService.hideAndShowTryAgain();
        });
    };
    LoginComponent.prototype.checkIfUserExists = function (data) {
        var email = this.loginForm.controls.email.value;
        var phone = this.loginForm.controls.phone.value;
        if (data.code && (data.code === AUTH_CODES.userExists)) {
            if (phone) {
                window.location.href = PROJECT_URL + ("/?phone=" + phone);
            }
            else if (email) {
                window.location.href = PROJECT_URL + ("/?email=" + email);
            }
        }
        else {
            this.loaderService.hide();
            this.popupComponent.showPopupError({ text: data.message });
        }
    };
    LoginComponent.prototype.formatCurrency = function (amount) {
        return this.currencyService.formatCurrency(amount);
    };
    LoginComponent.prototype.isContinueButtonDisabled = function () {
        return !this.loginForm.controls.promo.value ||
            (this.loginForm.controls.phone.invalid && this.loginForm.controls.email.invalid);
    };
    LoginComponent.prototype.isDepositButtonDisabled = function (termsAccept) {
        return this.isContinueButtonDisabled() ||
            !this.keywordsForm.controls.textarea.value ||
            !termsAccept.checked;
    };
    LoginComponent.prototype.onShowCashAppPopup = function () {
        var inputAmount = CASH_APP_PAYMENT_AMOUNT;
        this.showCashAppPopup = true;
        this.cashAppPopupData = {
            data: {
                payment: CHECKOUT_CHARGE_PAYMENT_TYPE.cashApp,
                inputAmount: inputAmount
            }
        };
        this.cashAppPopupData.data.cashappInfo = this.cashappInfo;
        // this.openNFTPopup({showNFTPopup: true, data: this.cashAppPopupData});
    };
    LoginComponent.prototype.onDepositConfirm = function ($event) {
        var _this = this;
        var email = this.loginForm.controls.email.value;
        var phone = this.loginForm.controls.phone.value;
        var promoCode = this.loginForm.controls.promo.value;
        var keywords = this.keywordsForm.controls.textarea.value;
        var token = $event.token;
        this.homeService.payWithCashApp(email, phone, promoCode, keywords, token).subscribe(function (data) {
            _this.setDepositConfirmResult(data);
        }, function () {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            //this.loaderService.hide();
        });
    };
    LoginComponent.prototype.setDepositConfirmResult = function (data) {
        try {
            if (data.status === true) {
                window.location.href = this.getAppLink(data.token);
            }
            else if (data.status === false) {
                this.checkIfUserExists(data);
            }
            else {
                this.loaderService.hideAndShowTryAgain();
            }
        }
        catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    LoginComponent.prototype.onCashAppPopupClose = function () {
        document.body.classList.remove('hide-menu');
        this.showCashAppPopup = false;
    };
    LoginComponent.prototype.getAppLink = function (token) {
        return PROJECT_URL + '/senetpro?scrollToGames=1&token=' + token;
    };
    LoginComponent.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginComponent_Factory() { return new LoginComponent(i0.ɵɵinject(i1.LanguageService), i0.ɵɵinject(i2.PhoneMaskService), i0.ɵɵinject(i3.LoaderService), i0.ɵɵinject(i4.CurrencyService), i0.ɵɵinject(i5.HomeService), i0.ɵɵinject(i6.PopupComponent), i0.ɵɵinject(i7.ActivatedRoute), i0.ɵɵinject(i8.FormBuilder)); }, token: LoginComponent, providedIn: "root" });
    return LoginComponent;
}());
export { LoginComponent };
